import React from "react"
import EventDetails from "../../components/EventsPage"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const event = {
  title: "[CLOSED] Meaningful Language Learning in Distance Learning",
  category: "Webinar",
  partner: "UPH Teachers College",
  location: "Zoom Meeting",
  date: ["17 Juli 2020"],
  price: "Gratis",
  slug: "meaningful-language-learning",
  isOver: true,
  thumb:
    "https://i.postimg.cc/qqhBCqHh/Whats-App-Image-2021-12-23-at-15-05-08.jpg",
  description: {
    __html: `<p><img style="max-width: 100%;" src="https://i.postimg.cc/qqhBCqHh/Whats-App-Image-2021-12-23-at-15-05-08.jpg" alt="Poster dari Webinar Meaningful Language Learning in Distance Learning" /></p>`,
  },
}

const EventPage = () => {
  return (
    <Layout>
      <SEO title={event.title} />
      <EventDetails event={event} />
    </Layout>
  )
}

export default EventPage
